import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avater: AVTR1,
  },
  {
    avater: AVTR2,
  },
  {
    avater: AVTR3,
  },
  {
    avater: AVTR4,
  },
];

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      <Swiper className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        {
          data.map(({ avater }, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avater} alt="Avater One" />
                </div>
                <h5 className='client__name'>Ernest Achiever</h5>
                <small className='client__review'>
                  Lorem ipsum dolor sit amet
                  consectetur, adipisicing elit. Nostrum ducimus sapiente laboru
                  dolorum unde aperiam sed est fuga consequatur. Rerum.
                </small>
              </SwiperSlide>);
          })
        }
      </Swiper>
    </section>
  )
}
export default Testimonials
