import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { FaAward, FaUser } from 'react-icons/fa'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experiences</h5>
              <small>3+ Years Working</small>
            </article>
            <article className="about__card">
              <FaUser className="about__icon" />
              <h5>Clients</h5>
              <small>15+ Worldwide</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>30+ Completed</small>
            </article>
          </div>
          {/* justified text */}
          <p style={{textAlign: 'justify'}}>
            I'm Nurullah Al Masum, a student of Computer Science and Engineering at RUET, Bangladesh.
            As a passionate Mobile Application Developer, I specialize in Java, Kotlin and Flutter to create innovative and user-friendly mobile applications.
            With a keen eye for detail and problem-solving expertise honed through solving over 300 problems on platforms like Codeforces,
            I craft intuitive user interfaces and implement cutting-edge functionalities. Having actively freelanced for over a year,
            I've gained practical experience and expanded my professional network. As I near the completion of my B.Sc.
            Engineering degree, I'm excited to leverage my skills to make a meaningful impact in the field of mobile app development.
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>

      </div>
    </section>
  )
}

export default About