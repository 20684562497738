import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className='service__head'>
            <h3>Native Android Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Custom Android Application Development.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>API Integration for seamless functionality.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>App Performance Optimization for optimal speed.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>App Testing and Bug Fixing to ensure stability.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Offline Support and Data Synchronization for uninterrupted usage.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Push Notifications and Messaging implementation for engaging user communication.</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className='service__head'>
            <h3>Flutter Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Cross-Platform Mobile App Development for Android and iOS.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Responsive UI Design for consistent user experiences.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>State Management for efficient data flow.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Firebase Integration for authentication, database, and storage.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Integration with Device APIs for accessing device functionalities.</p>
            </li>
          </ul>
        </article>

      </div>
    </section>
  )
}

export default Services