import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { SiCodeforces } from 'react-icons/si'

const HeaderSocials = () => {
    return (
        <div className='header__socials'>
            <a href="https://www.linkedin.com" target='_blank' >
                <BsLinkedin />
            </a>
            <a href="https://www.github.com" target='_blank' >
                <FaGithub />
            </a>
            <a href="https://www.codeforces.com" target='_blank' >
                <SiCodeforces />
            </a>
        </div>
    )
}

export default HeaderSocials